@mixin titleStyle($schemeColour) {
    letter-spacing: 0.07px;
    color: $schemeColour;
    text-transform: uppercase;
    @include fontSize(14px);
    font-weight: 700;
}

@mixin componentTitleStyle($schemeColour) {
    @include titleStyle($schemeColour);
    padding-bottom: 38px;
}

@mixin componentMargin {
    margin-top: 76px;
}