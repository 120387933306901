// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;  
  scroll-padding: var(--scroll-padding, 0rem);
}

body {
  overflow-x: hidden;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}