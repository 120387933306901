.food-safety-process-preview {
    &__add-hazard {
        display: flex;
        justify-content: end;
        padding: 1rem;
    }

    &__process-complete {
        display: flex;
        justify-content: center;
    }

    &__approve-or-edit-hazard {
        display: flex;
        justify-content: end;
    }    
}

@media (max-width: 1080px) {
    .food-safety-process-preview {

        &__add-hazard {            
            justify-content: center;
            padding: 1.5rem;
        }

        &__approve-or-edit-hazard {
            justify-content: center;
            padding: 0.5rem;
        }

        &__process-complete {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
        }
    }
}