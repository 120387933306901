@import 'commonMixins';
@import 'cashbackSummaryStyling';
@import 'cashbackProgressStyling';
@import 'cashbackBreakdownStyling';
@import 'historicCashbackChartStyling';

@mixin cashback-view-styling($schemeColour, $minWidthForScroll, $spendColour, $projectedColour, $checkpointBorderColour, $checkmarkColour: $okayGreen) {
    h1 {
        color: $darkTextColour;
    }
    
    .nav {
        .nav-link {
            border: none;
        }
        
        .active.nav-link {
            color: $darkTextColour;
            font-weight: bold;
            border-bottom: 2px solid $schemeColour;
        }
    }

    .period-timeframe {
        color: $schemeColour;
        @include fontSize(12px);
    }

    table {
        min-width: $minWidthForScroll;
    }

    .cashback-summary {
        @include cashback-summary-styling($schemeColour);
    }

    .cashback-progress {
        @include cashback-progress-styling(
            $schemeColour,
            $spendColour,
            $projectedColour,
            $checkpointBorderColour,
            $checkmarkColour);
    }

    .cashback-breakdown {
        @include cashback-breakdown-styling($schemeColour);
    }
    
    .historic-cashback-chart {
        @include historic-cashback-chart-styling($schemeColour);
    }

    .cashback-tab {
        @include componentMargin;
    }

    .selected-cashback-period {
        @include componentMargin;

        .summary-title {
            @include componentTitleStyle($schemeColour);
        }
    }

    @include scalingHorizontalPadding(768px, 2.5rem);
    @include scalingHorizontalPadding(992px, 5.5rem);
    @include scalingHorizontalPadding(1200px, 11rem);
    @include scalingHorizontalPadding(1900px, 22rem);
}