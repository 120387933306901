@use "sass:color";

@each $width in $border-widths {
    .border-width-#{$width} {
        border-width: #{$width}px !important;
    }
}

.border-dotted {
    border-style: dotted !important;
}

.border-dashed {
    border-style: dashed !important;
}

@each $color, $value in $theme-colors {
    a.link-#{$color}, .btn.btn-link.link-#{$color} {
        color: $value !important;

        &:hover {
            color: scale-color($value, $lightness: $link-shade-percentage) !important;
        }
    }

    .active {
        &.active-bg-#{$color} {
            background: $value !important;
            color: color-yiq($value);
        }
    }
}

.text-underline {
    text-decoration: underline !important;
}

.text-linethrough {
    text-decoration: line-through !important;
}

.h-fit-content {
  height: fit-content !important;
}

@each $size, $value in $sizes {
    .mw-#{$size} {
        max-width: $value !important;
    }

    .mh-#{$size} {
        max-height: $value !important;
    }
}

.link-unstyled {
    &,
    &:visited,
    &:hover,
    &:active,
    &:focus,
    &:active:hover {
        font-style: inherit;
        color: inherit;
        font-size: inherit;
        text-decoration: none;
        font-variant: inherit;
        font-weight: inherit;
        line-height: inherit;
        font-family: inherit;
    }
}

@each $name, $value in $font-sizes {
    .font-#{$name} {
        font-size: $value !important;
    }
}

@each $name, $value in $square-button-sizes {
    .btn-square-#{$name} {
        width: $value !important;
        height: $value !important;
        max-width: 100%;
        max-height: 100%;
    }
}

// custom table stacking
.table-responsive-stack tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}

.table-responsive-stack td, .table-responsive-stack th {
    /* flex-grow | flex-shrink | flex-basis   */
    display:block;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.table-responsive-stack .table-responsive-stack-thead {
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .table-responsive-stack tr {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        border-bottom: 3px solid #ccc;
        display:block;
    }

    /*  IE9 FIX   */
    .table-responsive-stack td {
        float: left\9;
        width:100%;
    }
}

.bs-table-row {
    border-top-width: 0;
    border-right-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-style: solid;
    color: black;
}

.bs-table-row-default {
    background-color: $bs-default;
    border-color: $bs-default-border;    
}

.bs-table-row-primary {
    background-color: $bs-primary;
    border-color: $bs-primary-border;
    
}

.bs-table-row-warning {
    background-color: $bs-warning;
    border-color: $bs-warning-border;
}

.bs-table-row-danger {
    background-color:$bs-danger;
    border-color:$bs-danger-border;
}

.bs-table-header-primary {
    background-color: $bs-default;
    border-color: $bs-default-border;
    color: black;
    border-top-width: 1px;
    border-right-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-style: solid;
}

// images

.responsive-image {
    max-height: 480px;
    object-fit: contain;
}

@media screen and (max-width: 768px) {
    .responsive-image {
        max-height: 320px
    }
}

.object-fit-cover {
    object-fit: cover !important;
}

.p-btn {
    padding: $btn-padding-y $btn-padding-x !important;
}