@import "../cashback-view/index";

.calor-cashback-deal-content {
    @include cashback-view-styling(
        $calorRed,
        600px,
        $calorRed,
        desaturate(lighten($calorRed, 40%), 20%),
        lighten($calorRed, 30%),
    );
}