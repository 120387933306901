@mixin cashback-summary-styling($schemeColour) {
    border: 1px solid $fadedGrey;
    
    .summary-element {
        
        .title {
            @include titleStyle($schemeColour);
            min-height: 25px;
        }

        .amount {
            font-weight: bold;
            @include fontSize(30px);
            color: $darkTextColour;

            @include scalingFontSize(375px, 40px);
            @include scalingFontSize(425px, 50px);
            @include scalingFontSize(768px, 58px);

            @media (min-width: 375px) {
                white-space: nowrap;
            }
        }

        .text {
            color: $darkTextColour;
            @include fontSize(12px);
            padding: 0 34px;
            line-height: 15px;
        }
        .faq-link {
            color: $schemeColour;
            text-decoration: underline;
            font-weight: bold;
            font-style: italic;
            @include fontSize(12px);
        }
    }

    @include scalingPadding(360px, 1rem 1.25rem);
    @include scalingPadding(360px, 2rem 1.25rem);
}

.no-data-message {
    color: $darkTextColour;
    display: block;
    padding-top: 6px;
    text-align: center;
    @include fontSize(16px);
    font-weight: bold;
}