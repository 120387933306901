@import "./functions.scss";

@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}

@mixin truncate($width) {
  width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin scalingFontSize($minScreenWidth, $fontSize) {
  @media (min-width: $minScreenWidth) {
    @include fontSize($fontSize);
  }
}

@mixin scalingHorizontalPadding($minScreenWidth, $padding) {
  @media (min-width: $minScreenWidth) {
    padding-left: $padding !important;
    padding-right: $padding !important;
  }
}

@mixin scalingPadding($minScreenWidth, $padding) {
  @media (min-width: $minScreenWidth) {
    padding: $padding !important;
  }
}