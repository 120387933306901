//
// slimscroll.scss
//


.ps .ps__rail-y {
    background: transparent !important;
    &:hover {
        background: transparent !important;
    }
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
    width: 6px !important;
    background: rgb(158, 165, 171);
}