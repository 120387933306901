.iframe-video-container {
    display: flex;
    justify-content: center;
}

.iframe-video {
    width: 650px;
    height: 366px; 
}

@media (max-width: 520px) {
    .iframe-video {
        width: 400px;
        height: 225px; 
    }
}