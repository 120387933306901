.card-slide-enter {
    transform: translateX(-50%);
    opacity: 0;

    &-active {
        transform: translateX(0);
        opacity: 1;
        transition: transform 500ms, opacity 500ms;
    }
}

.card-slide-exit {
    transform: translateX(0);
    opacity: 1;

    &-active {
        transform: translateX(50%);
        opacity: 0;
        transition: transform 500ms, opacity 500ms;
    }
}