.dark-text-color {
  color: $darkTextColour;
}

.white-text-color, .reject-claim-button {
  color: $ncassWhite;
}

.btn-orange,
.btn-orange:focus {
  margin-left: 0 !important;
  border-radius: 0 !important;
  border: 1px solid $bookerOrange !important;
  background: $bookerOrange !important;
  color: $ncassWhite !important;
}

.card {
  color: $ncassBlack;
}

@include media-breakpoint-down(sm) {
  .btn-sm-block {
    display: block;
    width: 100% !important;
  }
}

.ncass-green-color {
  color: $ncassGreen;
}

.text-blue {
  color: blue !important;
}

.excluded-red-color {
  color: $excludeRed;
}

.ncass-green-background {
  background-color: $ncassGreen;
  border: 1px solid $ncassGreen !important;
  &:focus {
    box-shadow: none !important;
  }
}

.error-red-background, .reject-claim-button {
  background-color: $errorRed !important;
  border: 1px solid $errorRed !important;
  &:focus {
    box-shadow: none !important;
  }
}

.font-size-16 {
    @include fontSize(16px);
}

.font-size-20 {
  @include fontSize(20px);
}

.border-dark-grey {
  border-color: $ncassBlack !important;
}

.border-light-grey {
  border-color: $ncassLightGray !important;
}

.disabled-input-white {
  background-color: #fff  !important;
  border: 1px solid #dee2e6 !important;
  color: #6c757d !important;

}

.flex-center-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.full-width {
  width: 100%;
}

@-moz-document url-prefix() {
  .flex-align-content {
    display: flex;
    align-content: center;
  }
}

.h-1 {
  height: 1px;
}

.h-inherit {
  height: inherit;
}

.mr-auto {
  margin-right: auto;
}

.copy-to-be-approved::before {
  content: "COPY TO BE APPROVED: ";
  display: inline;
  color: $excludeRed;
}

