@import '../../mixins';

.fm-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 2px 6px #00000029;
    padding: 20px 20px;
    margin-bottom: 60px;

    .btn-end-period {
        background-color: #c82216;
        box-shadow: 0px 2px 6px 0px rgba(200, 34, 22, 0.5);
    }

    .period-name {
        font: ExtraBold 30px/41px Nunito;
        letter-spacing: 0;
        color: #323a47;
        padding-left: 10px;
    }

    .period-status {

        @mixin status-color ($color) {
            .period-status {
                color: $color;
            }

            .dot {
                background: $color;
            }
        }

        &-active {
            @include status-color($ncassGreen);
        }

        &-closed {
            @include status-color($inactiveGrey);
        }

        &-pending {
            @include status-color($bookerOrange);
        }
    }

    .period-dates {
        padding-left: 20px;

        .btn-edit-payout-date {
            @include fontSize(12px);
        }
    }

    .period-status {
        font: Bold 10px/14px Nunito;
        letter-spacing: 0.3px;
        color: #1aa115;
        margin-top: 25px;
    }

    .period-date-label {
        font: Bold 18px/24px Nunito;
        color: #323a47;
    }

    .period-date {
        font: Bold 26px/35px Nunito;
        color: #323a47;
    }

    .dot {
        width: 8px;
        height: 8px;
        background: #1aa115 0% 0% no-repeat padding-box;
        margin-top: 27px;
        margin-right: 7px;
        border-radius: 50%;
    }

    .heading {
        font: ExtraBold 14px/19px Nunito;
        color: #323a47;
        padding-left: 10px;
    }

    hr {
        margin-top: 0px;
        border-top: 2px solid #e3eaef;
    }

    .file-progress-bar {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #f5f5f5;
        padding: 10px 20px 10px 20px;

        .validating-failed-action {
            text-align: right;
        }
    }

    .file-upload-name {
        font: 14px/19px Nunito;
        letter-spacing: 0;
        color: #1f2329;
    }

    .p-file-status {
        font: 12px/16px Nunito;
        letter-spacing: 0;
        color: #666666;
        display: inline-block;
        text-align: center;
        width: 100%;
    }

    .col-pixel-width-20 {
        flex: 0 0 1.25rem;
    }

    .col-pixel-width-60 {
        flex: 0 0 0.4166666666666667rem;
    }

    .col-pixel-width-100 {
        flex: 0 0 6rem;
    }

    .progress-bar-wrapper {
        %circle {
            font-size: 16px;
            border-radius: 50%;
            border: 2px solid #707070;
            line-height: 100%;
            width: 20px;
            height: 20px;
            display: inline-block;
        }

        .mdi-check-circle {
            @extend %circle;
            color: #36a400;
        }

        .mdi-checkbox-blank-circle-outline {
            @extend %circle;
        }

        .mdi-close-circle.red {
            @extend %circle;
            color: #b80101;
        }

        .mdi-close-circle.orange {
            @extend %circle;
            color: #e67300;
        }

        .mdi-cached.yellow {
            @extend %circle;
            color: white;
            background-color: #e2a600;
        }

        .mdi-cached.grey {
            @extend %circle;
            color: white;
            background-color: #979797;
        }

        %link {
            width: 75px;
            display: inline-block;
            border: 1px solid #707070;
            margin-bottom: 4px;
        }

        .link {
            @extend %link;
        }

        .link-dashed {
            @extend %link;
            border: 1px dashed #707070;
        }
    }

    @media (min-width: 1920px) and (max-width: 2560px)  {
        padding: 20px 200px;
    }
}
