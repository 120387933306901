@mixin historic-cashback-chart-styling($schemeColour) {
    @include componentMargin;

    .title {
        @include titleStyle($schemeColour);
    }

    table {
        .col-spacing {
            width: auto;
        }
    }
}