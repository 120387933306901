$cashbackLimitOffset: 3.75em;
$checkpointDiameter: 40px;
$checkmarkSize: 22px;

.cashback-progress {
    thead {
        .progress-col {
            .cashback-limit {
                position: relative;
                left: $cashbackLimitOffset;
            }
        }

        .spacing-col {
            width: $cashbackLimitOffset;
        }
    }

    tbody {
        .progress-col {
            height: 62px; //Set so nested divs render at the correct height without text content.

            .cashback-progress-bar {
                position: relative;
                height: 100%;

                .progress-bar-section {
                    height: 100%;
                    border-radius: 16px;
                    position: absolute;
                    top: 4px;

                    &.background {
                        background-color: $cashbackProgressBarDefaultBackground;
                    }
                }

                .projected-progress-bar-tooltip-area {
                    position: absolute;
                    height: 100%;
                }

                .checkpoint-overlay {
                    position: absolute;
                    width: 100%;
                    pointer-events: none;

                    .checkpoint {
                        width: $checkpointDiameter;
                        height: $checkpointDiameter;

                        // Tried "border: 3px solid" but then the styling for the schemes didn't set the colours correctly, so they are set as seperate properties.
                        border-radius: 3px;
                        border-style: solid;

                        border-radius: 50%;
                        position: relative;
                        left: calc($checkpointDiameter / 2);

                        .checkmark {
                            @include fontSize($checkmarkSize);
                            line-height: $checkmarkSize;
                            width: $checkmarkSize;
                            height: $checkmarkSize;
                            margin: 6px;
                        }
                    }
                }
            }
        }
    }
}