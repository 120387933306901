.tier-comparison-card-section {
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: solid 1px $ncassLightGray
}

.tier-selector-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-bottom: 15px;
}

.tier-selector-button {
    margin: 10px;
    text-align: center;
}

@media (min-width: 1200px) {
    .tier-selector-container {
        justify-content: center !important;
        flex-wrap: nowrap !important;
    }
}

.flex-center {
    display: flex;
    justify-content: center !important;
}