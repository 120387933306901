.food-safety-action-button {
    padding-left: 3px !important;
    padding-right: 3px !important;
    font-size: 22px !important;

    &--delete{
        color: $excludeRed !important;

    }
    &--update{
        color: $ncass-info !important;
    }
}