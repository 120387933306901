@mixin cashback-progress-styling($schemeColour, $spendColour, $projectedColour, $checkpointBorderColour, $checkmarkColour: $okayGreen) {
    @include componentMargin;
    width: 100%;

    .title {
        @include componentTitleStyle($schemeColour);
    }

    .id-col {
        line-height: 32px;
        @include fontSize(14px);
        font-weight: 600;
        color: $darkTextColour;
    }

    thead {
        .id-col {
            @include fontSize(14px);
            font-weight: 600;
            line-height: 40px;
        }

        .percentage {
            color: $darkTextColour;
            font-weight: 700;
            @include fontSize(18px);
        }

        .spend {
            color: $darkTextColour;
            @include fontSize(14px);
        }
    }

    .cashback-progress-bar {
        .projected {
            background-color: $projectedColour;
        }
        
        .spend {
            background-color: $spendColour;
        }

        .checkmark {
            color: $checkmarkColour;
        }

        .checkpoint {
            border-color: $checkpointBorderColour;
        }
    }
}