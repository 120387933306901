@import "./functions.scss";

.nav-item {
    cursor: pointer;
}

.back-to-control-panel-button {
    color: $ncassWhite;
    line-height: calculateRem(20px);

    i {
        font-size: calculateRem(20px);
        vertical-align: middle;
    }
}

@import "components/rewards/booker/cashback-view";
@import "components/rewards/cashback-view/CashbackProgress";
@import "components/offers/booker/signup/Index";
@import "components/rewards/calor/claim-form/index";
@import "components/rewards/calor/cashback-view";
@import "components/my-account/renew/membership-tier-button";
@import "components/my-account/renew/tier-comparison-card";
@import "components/embedded-video";
@import "components/instructions-screenshot";
@import "components/unit-premises/upload-unit-premises-photo-card";
@import "components/unit-premises/unit-card";
@import "components/unit-premises/food-safety-hazard-element-actions.scss";
@import "components/markdown-editor/_markdown-editor.scss";
@import "components/risk-assessments/food-safety/_food-safety-applicable-process-state-options";
@import "components/risk-assessments/food-safety/_food-safety-process-preview";
@import "components/risk-assessments/food-safety/_food-safety-process-preview-mobile-view";
@import "components/risk-assessments/food-safety/_food-safety-process-preview-table";
@import "components/risk-assessments/food-safety/food-safety-process-state";

@import "./_mixins";
@import "./_functions";
@import "./_variables";
@import "./_utilities";

//Transitions
@import "transitions/card-slide";
@import "transitions/fade";