.food-safety-applicable-process-state-options {
    margin-top: 1 rem;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1080px) {
    .food-safety-applicable-process-state-options {
        padding: 1 rem;
        flex-direction: row;

        &__options-list {
            margin-left: .75rem;
        }
    }
}