@import "../cashback-view/index";

.booker-cashback-deal-content-new {
    @include cashback-view-styling(
        $bookerOrange,
        500px,
        $bookerOrange,
        #FFE5C2,
        #FFA500
    );
}