.fade-enter, .fade-appear {
    opacity: 0;
    
    &-active {
        opacity: 1;
        transition: opacity 500ms;
    }
}

.fade-exit {
    opacity: 1;

    &-active {
        opacity: 0;
        transition: opacity 500ms;
    }
}