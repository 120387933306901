.food-safety-process-preview-table {
    table-layout: fixed;
    thead {
        tr {
            border: solid 1px $ncassGreen;
            background-color: $ncassGreen;
            color: $ncassWhite;
        }
        tr:last-of-type {
            border: none;
            background-color: $ncassLightGray;
            color: $ncassBlack;
            th {
                border: none;
            }
        }
    }
}

@media (max-width: 1079px) {
    .food-safety-process-preview-table {
        display: none;
    }
}