.food-safety-process-preview-mobile-view {
    color: $gray-700;
    &__hazard {
        margin-top: 1.5rem;
    }

    &__header {
        margin-top: 1rem;
        background-color: $ncassGreen;
        color: $ncassWhite;
        padding: 1rem;

        h3 {
            margin-block-start: 0;
            margin-block-end: 0;
        }
    }

    &__hazard-name {
        background-color: $ncassGreen;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        color: $ncassWhite;
        display: flex;
        padding: 1rem;
        h4 {
            margin-block-start: 0;
            margin-block-end: 0;
        }

        &--collapsed {            
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    &__elements-list {
        padding: 1rem;
        border-left: solid 1px $createNewPeriodBorder;
        border-right: solid 1px $createNewPeriodBorder;
        border-bottom: solid 1px $createNewPeriodBorder;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    
    &__element-separator {
        border:1px solid $createNewPeriodBorder;
        margin-top: 3px !important;
    }
}

@media (min-width: 1080px) {
    .food-safety-process-preview-mobile-view {
        display: none;
    }
}