@mixin cashback-breakdown-styling($schemeColour) {
    @include componentMargin;
    overflow-y: auto;

    i {
        &.status-approved {
            color: $okayGreen;
        }
        
        &.status-pending {
            color: $schemeColour;
        }
    }

    table {
        white-space: nowrap;
        color: $darkTextColour;
        vertical-align: middle;
    }

    .title {
        @include componentTitleStyle($schemeColour);
    }

    .header-row {
        font: Bold 16px/22px Nunito;
        height: 39px;
        border-bottom: 1px solid $darkTextColour;
    }

    .account-row {
        border-bottom: 1px solid $fadedGrey;
        
        &:last-of-type {
            border-bottom: none;
        }
    }
}