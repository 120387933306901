.booker-signup-container {
  h3 {
    font: Bold 24px/32px Nunito;
    margin: 0px;
    border-top: 1px solid $ncassLightGray;
    padding-top: 2.5rem;
  }

  .booker-signup-form-container {
    h2 {
      font: Bold 32px/43px Nunito;
    }

    .card {
      background: $ncassWhiteSmoke 0% 0% no-repeat padding-box;
    }

    .booker-signup-text {
      font: normal 18px/26px Nunito;
    }

    .booker-signup-radio-group {
      height: 3.5rem;

      .form-control {
        background-color: transparent;
      }
    }

    .booker-account-number-group {
      height: 3rem;
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      background-image: none;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  .btn-booker-signup {
    width: 121px;
    height: 38px;
  }

  .booker-signup-small-text {
    font: normal 16px/22px Nunito;
    color: $ncassBlack;
  }

  .booker-signup-accountNumber {
    width: 182px;
    height: 31px;
    text-align: center;
    font: 22px/30px Nunito;
    font-weight: 600;
    letter-spacing: 1.1px;
  }

  .form-check-label {
    color: $ncassBlack !important;
  }

  input[type="checkbox"] + .form-check-label {
    font: normal 18px/24px Nunito;
  }

  .booker-signup-response-text {
    font: Italic 14px/19px Nunito;
    margin: 0px;
  }
}

@include media-breakpoint-down(sm) {
  .content-page {
    padding: 0;
  }

  .container-fluid {
    padding: 0;
  }

  .page-title-box {
    padding-left: 1.5rem;
  }
}

@media (min-width: 1920px) {
  .booker-signup-container {
    .card-body {
      padding: 2.5rem 4rem;
    }

    .booker-signup-form-container {
      margin: 0px;
      margin-bottom: 5rem;
    }
  }
}
