//
// custom-variables.scss
//

// Shadow
$shadow:                            0px 0px 35px 0px rgba(154, 161, 171, 0.15);
$shadow-lg:                         0 0 45px 0 rgba(0, 0, 0, 0.12);

// Background left-sidebar
$bg-leftbar:                      #ffffff;
$bg-topnav:                         linear-gradient(to bottom, #8f75da, #727cf5);

// Topbar Height
$topbar-height:                     70px;

// Leftbar width
$leftbar-width:                     250px;

// Boxed Layout
$boxed-layout-width:                1300px;
$boxed-layout-bg:                 #ffffff;

// Menu item colors
$menu-item:                       #6c757d;
$menu-item-hover:                 $ncass-green;
$menu-item-active:                $ncass-green;
$menu-item-wip:                   $orange;
$menu-item-wip-hover:             $pink;
$menu-item-wip-active:            $pink;

// Rightbar Width
$rightbar-width:       260px;
$rightbar-bg:                     #ffffff;
$rightbar-title-bg:               #313a46;
$rightbar-title-color:            #ffffff;
$rightbar-title-btn-bg:           #444e5a;
$rightbar-title-btn-color:        #ffffff;
$rightbar-overlay-bg:             #37404a;

// Topbar Background
$bg-topbar:                       #ffffff;

// Topbar Search
$bg-topbar-search:                #f1f3fa;

// Font weight
$font-weight-semibold:              600;

// Dropdown Large
$dropdown-lg-width:                 320px;

// Page title color
$page-title-color:                  inherit;

// Nav-pill background
$nav-pills-bg:                    #e3eaef;

// Custom-accordion
$custom-accordion-title-color:    #313a46;

// Dragula demo background
$dragula-bg:                      #e3eaef;

// Form wizard header background
$form-wizard-header-bg:           #e3eaef;

// Text title color
$text-title-color:                #6c757d;

// card Loader background
$card-loader-bg:                  #313a46;

// Chat widget
$chat-primary-user-bg:            #fef5e4;
$chat-secondary-user-bg:          #f1f3fa;

// User authentication Background
$auth-bg:                         #ffffff;
$auth-bg-pattern-img:               url("../../../images/bg-pattern-light.svg");

$border-widths: (1, 2, 3, 4, 5, 6);

$font-sizes: (
    xx-small: xx-small,
    x-small: x-small,
    small: small,
    medium: medium,
    large: large,
    x-large: x-large,
    xx-large: xx-large,

    smaller: smaller,
    larger: larger,

    25: 25%,
    50: 50%,
    75: 75%,
    125: 125%,
    150: 150%,
    175: 175%,
    200: 200%,
);

$square-button-sizes: (
    small: 2rem,
);