.instructions-screenshot {
    &__wrapper {
        margin-bottom: .8rem;
        padding: .5rem;
        width: fit-content;
        background-color: white;
        width: 100%;
    }

    &__img {
        max-width: 95%;
        box-shadow: -0.3rem 0.3rem 0.5rem $fadedGrey;
        border-radius: 8px;
    }

    &__img-mobile {
        display: none;
    }
}

@media (max-width: 520px) {
    .instructions-screenshot {
        &__img {
            &--hide {
                display: none;
            }
        }

        &__img-mobile {
            display: block;
            max-width: 95%;
            box-shadow: -0.3rem 0.3rem 0.5rem $fadedGrey;
            border-radius: 8px;
        }
    }
}