.membership-tier-button {
    width: 120px !important;
    font-weight: 800;
    border-bottom-color: $ncassGreen !important;
    border-top-color: $ncassGreen !important;
    border-right-color: $ncassGreen !important;
    border-left-color: $ncassGreen !important;
}

.membership-tier-button-unselected {
    background-color: #fff !important;
    color:$ncassGreen !important;
}

.membership-tier-button-selected {
    background-color: $ncassGreen;
    color:#fff;
}